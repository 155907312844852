.video {
  width: 100vw;
  height: 100vh;
  background-color: #000;
  position: relative;
}

.btn {
  position: absolute;
  bottom: 50px;
  left: 50px;
  opacity: 0.7;
}

.text-alert {

}

.button-alert{

}

.selected {

}